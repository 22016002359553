.category-tiles {
  .category-tile {
    min-height: 50vh;

    $activeBorderSize: 2px;

    &-title {
      position: relative;
      border-bottom: $activeBorderSize solid transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: $activeBorderSize solid $carbon--cyan-50;
      font-weight: bold;
    }
  }
}