
//formularz z landami
.pref-3-form {
  display: flex;

  .basic-preference-settings {
    width: 25%;
  }
}

.land-map {
  width: 75%;
  position: relative;

  img.map-markers {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: auto;
  }

  svg {
    width: 100%;
    max-width: 700px;
    height: auto;

    path {
      backface-visibility: hidden;
      transition: all .3s ease;
      cursor: pointer;
    }

    path:hover, path.active {
      fill: black;
      z-index: 2;
      position: relative;
    }
  }
}