.pref-settings-modal {
  overflow-y: scroll;

  .bx--modal-container {
    max-height: 95vh;
    overflow: visible;

    .bx--modal-content {
      margin-bottom: 0;
      overflow: visible;
    }
  }

  &.is-visible.opaque {
    opacity: 0;
  }
}