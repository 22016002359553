@import '~carbon-components/scss/globals/scss/vars';

@import 'components/CategoryTiles/CategoryTiles';
@import 'components/Sidebar/Sidebar';
@import 'components/LandMap/LandMap';
@import 'components/TypeCard/TypeCard';
@import 'components/EditModal/EditModal';
@import 'components/SyncInfo/SyncInfo';

.mb-5 {
  margin-bottom: $spacing-05;
}

.mb-7 {
  margin-bottom: $spacing-07;
}

.mb-10 {
  margin-bottom: $spacing-10;
}

.mr-5 {
  margin-right: $spacing-05;
}

.p-5 {
  padding: $spacing-05;
}

.p-0 {
  padding: 0;
}

.bold {
  font-weight: bold;
}

.app-notify {
  position: fixed;
  right: 30px;
  z-index: 9001;
  bottom: 30px;
  transition: all .3s ease;
}

.preferences-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .name {
    display: flex;
    align-items: center;
  }
}

.bx--flex-line {
  display: flex;
  align-items: center;
}

.bx--flex-end {
  display: flex;
  align-items: flex-end;
}

.pref-tag {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.google-maps-wrapper {
  min-height: 45vh;
  max-height: 100%;
}

.basic-preference-settings {
  width: 50%;
}

.bx--slider-container {
  width: 100%;
}